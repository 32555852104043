<template>
  <div class="page-container">
    <div class="header">
      <page-header @navBack="navBack" :back="true" :pageTitle="pageTitle">
        <template v-slot:default>
          <span @click="navToScoreList">成绩单</span>
        </template>
      </page-header>
    </div>
    <div class="page-body">
      <div class="content">
        <div class="section-top">
          <div class="left">
            <img :src="avatar" alt="avatar" />
          </div>
          <div class="right">
            <h4>{{ name }}</h4>
            <span
              >最好成绩{{ bestInfo.score || "-" }}分，用时{{parseInt(this.bestInfo.testDuration/60) || "-"}}分钟</span>
          </div>
        </div>
        <div class="section-content">
          <div class="list-item">
            <span class="name c-666">{{belongOrganization==='13'?'测试':'考试'}}类型：</span>
            <span class="value c-999">{{ categoryname || "-" }}</span>
          </div>
          <div class="list-item">
            <span class="name c-666">{{belongOrganization==='13'?'测试':'考试'}}标准：</span>
            <span class="value c-999"
              >{{
                testInfo.singleChoiceNum
                  ? testInfo.singleChoiceNum +
                    testInfo.judgeChoiceNum +
                    testInfo.multiChoiceNum
                  : "-"
              }}题 {{ testInfo.examinationTime || "-" }}分钟</span
            >
          </div>
          <!-- <div class="list-item">
            <span class="name c-666">合格标准：</span>
            <span class="value c-999"
              >{{ testInfo.examinationPass || "-" }}分（满分{{
                testInfo.examinationTotal || "-"
              }}分）</span
            >
          </div> -->
          <div class="list-item">
            <span class="name c-666">{{belongOrganization==='13'?'测试':'考试'}}规则：</span>
            <span class="value c-999">根据最新法律法规</span>
          </div>
          <div v-if="testInfo.remark" class="list-item">
            <span class="name c-666">温馨提示：</span>
            <span class="value c-999">{{ testInfo.remark }}</span>
          </div>
        </div>
        <div class="section-bottom">
          <van-button class="btn" type="primary" block @click="testing"
            >开始{{belongOrganization==='13'?'测试':'考试'}}
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../components/page-header.vue";
import { getPeriodInfo, getTestInfo, getBest, getPlanInfo } from "../../api/user";

export default {
  data() {
    return {
      pageTitle: "",
      subject: 1,
      testInfo: {},
      bestInfo: {},
      gradientColor: {
        "0%": "#FFE1C1",
        "100%": "#FF8506",
      },
      statusMap: {
        0: "已作废",
        1: "已上传",
        2: "待上传",
        3: "待签退",
      },
      studyList: [
        { time: "2020-10-10 20:20", min: 40, status: 0 },
        { time: "2020-10-10 20:20", min: 20, status: 1 },
        { time: "2020-10-10 20:20", min: 10, status: 2 },
        { time: "2020-10-10 20:20", min: 50, status: 3 },
      ],
      markValue: 3,
      belongOrganization: '',
      testDuration: 0,
    };
  },
  computed: {
    userid() {
      return this.$store.state.user.userid;
    },
    categoryid() {
      return this.$store.state.user.categoryid;
    },
    courseid() {
      return this.$store.state.user.courseid;
    },
    categoryname() {
      return this.$store.state.user.categoryname.replace(/\([^)]*\)/g,"");
    },
    avatar() {
      return this.$store.state.user.avatar;
    },
    name() {
      return this.$store.state.user.name;
    },
    area() {
      return this.$store.state.user.cityCode;
    },
  },
  components: {
    PageHeader,
  },
  mounted() {
    this.subject = this.$route.query.subject;
    this.getTestInfo();
    this.getPlanInfo();
    this.getBest();
    if (this.subject === 1) {
      this.pageTitle = "科目一" + this.pageTitle;
    } else if (this.subject === 4) {
      this.pageTitle = "科目四" + this.pageTitle;
    }
  },
  methods: {
    // 获取学习计划
		getPlanInfo() {
			getPlanInfo(this.userid).then(res => {
				console.log('res',res);
				this.belongOrganization = res.belongOrganization;
        if (this.belongOrganization === '13') {
          this.pageTitle= "模拟测试"
        }else{
          this.pageTitle= "模拟考试"
        }
			})
		},
    getPeriodInfo() {
      getPeriodInfo(this.userid)
        .then(() => {
          // this.testInfo = res;
          // this.getTestInfo();
        })
        .catch(() => {
          // this.$message.error(err);
        });
    },
    getTestInfo() {
      getTestInfo({
        categoryId: this.categoryid,
        subject: this.subject,
        area: this.area,
        studentId: this.userid,
      })
        .then((res) => {
          console.log(res);
          this.testInfo = res[0] || {};
          this.testDuration = parseInt(this.bestInfo.testDuration/60)
          localStorage.setItem("examinationPass",this.testInfo.examinationPass)
        })
        .catch(() => {});
    },
    testing() {
      // this.testInfo.questionManageEntityList
      if (!(this.testInfo && this.testInfo.examinationTime)) {
        this.$dialog.alert({
          message: `暂无${this.belongOrganization==='13'?'测试':'考试'}安排，请联系管理员`,
          theme: "round-button",
          confirmButtonColor: "#fca142",
          width: 290,
        });
        return;
      }
      localStorage.setItem("examInfo", JSON.stringify(this.testInfo));
      this.$router.push({ path: "/mock", query: { subject: this.subject } });
    },
    getBest() {
      getBest({
        studentId: this.userid,
        categoryId: this.categoryid,
        subject: this.subject,
      }).then((res) => {
        this.bestInfo = res || {};
      });
    },
    navToScoreList() {
      this.$router.push({
        path: "/score-list",
        query: { subject: this.subject },
      });
    },
    navBack() {
      this.$router.push({ path: '/train'})
      // this.$router.push({ path: '/video', query: { subject: this.subject, courseid: this.courseid } })
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: absolute;
  width: 100%;
  height: 200px;
  padding-bottom: 14px;
  border-bottom-left-radius: 26px 10px;
  border-bottom-right-radius: 26px 10px;
}

.tips {
  padding: 4px 12px;
  font-size: 12px;
}

.page-container {
  min-height: 100vh;
  background: #f5f5f5;
}

.page-body {
  margin-top: 0;
  padding: 100px 15px 15px;

  .content {
    position: relative;
    top: -14px;
    background: #fff;
    border-radius: 14px;
    padding: 15px;

    .section-top {
      display: flex;

      .left {
        flex: 0 0 76px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 76px;
          object-fit: cover;
          border-radius: 76px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          font-size: 17px;
          color: rgba(255, 147, 35, 0.6);
          margin: 0 0 4px 0;
        }

        span {
          color: #666;
        }
      }
    }

    .section-content {
      padding: 20px 10px;
      font-size: 15px;

      .list-item {
        line-height: 1.6;
        margin-bottom: 4px;

        &.tips {
          margin-top: 40px;
          font-size: 13px;
        }
      }
    }

    .section-bottom {
      margin-top: 20px;
    }
  }
}

// .van-popup {
//   background: transparent;
// }
.van-popup .van-popover__arrow {
  background: #fca142;
}

.van-popover__content {
  background: #fca142;
  color: #fff;
}

.van-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-wrap {
  position: relative;
  height: 200px;

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
    margin: -80px 0 0 -80px;
    background-image: url(~@/assets/icon-circle-sm.png);
    background-size: cover;

    .circle-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      .title {
        margin: 0 0 4px 0;
        color: #ff8506;
        font-size: 20px;
      }

      span {
        color: #999;
        font-size: 11px;
      }
    }
  }
}

.popup-content {
  width: 290px;
  // border-radius: 12px;
  overflow: hidden;
  background: #fff;

  & > .title {
    background: linear-gradient(90deg, #fca142, #ff8506);
    padding: 15px;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }

  .content {
    margin: 20px 0 10px;
    padding: 0 10px;
    font-size: 12px;

    .list {
      display: flex;
      align-items: center;
      margin-top: 10px;

      span {
        display: inline-block;
        width: 50%;
      }
    }
  }

  .btn-wrap {
    display: flex;
    padding: 15px;

    .btn {
      width: 40%;
      font-weight: 500;
    }
  }
}
</style>
